@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500&family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,500;1,600&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
:root {
  --p-color: #813032;
  --a-color: #e78e2b;
  --s-color: #2856c8;
  --t-color: #f6f7f8;
  --h-color: #ba0e1e;
  --g-color: #f8e6e8;
  --green-color: #e78e2b;
  --h-family: "Work Sans", sans-serif;
  --p-family: "Poppins", sans-serif;
  --white-color: #fff;
  --black-color: #000;
}

.clearfix {
  content: "";
  clear: both;
  display: table;
}

* {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--h-family);
  /* font-weight: 600; */
  letter-spacing: 1px;
  margin-bottom: 0;
}

p {
  font-family: var(--p-family);
  margin-bottom: 0;
}

a {
  font-family: var(--p-family);
}

.linked {
  color: var(--white-color);
  text-decoration: none;
  padding: 3px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.profile {
  float: right;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding: 0 22px;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: var(--a-color) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ...................Navbar............... */

#navbar_top {
  box-shadow: 0 0 5px #666 !important;
  background-color: rgb(245 240 240);
}

.bottom_header {
  /* background-color: rgb(236 255 242); */
  box-shadow: 0 0 5px #666 !important;
  background-color: rgb(245 240 240);
}

.top_banner {
  background-color: var(--p-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}

.header_contents {
  color: var(--white-color);
  padding-top: 10px;
}

.header_contents h1 {
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
}

.mid_header {
  background-color: var(--p-color);
  align-items: center;
  padding-top: 15px;
}

.logo {
  height: auto;
  width: 100px;
  text-align: center;
  margin-bottom: 12px;
}

.logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.search_field .input-group-text {
  border-radius: 0 25px 25px 0;
}

.search_field .input-group .form-control {
  border-right: none;
}

.search_field .form-control {
  padding: 18px !important;
  border-radius: 25px 0 0 25px !important;
}

.form-control:focus {
  border-color: var(--g-color) !important;
  box-shadow: none !important;
}

.input-group :placeholder-shown {
  font-family: var(--h-family);
}

.input-group-text {
  /* background-color: #c3e0e1 !important; */
  background-color: white;
}

.cart-div {
  margin-left: 10px;
}

.cart-div a i span {
  font-size: 12px;
}

@media only screen and (max-width: 820px) {
  /* .icons i,
  .span-sign {
    font-size: 10px !important;
    padding: 2 !important;
  } */
  .icons span {
    margin-right: 10 !important;
  }
  .mid_header {
    padding-top: 7px;
  }
  .filter-btn {
    margin: 10px 0 !important;
  }
}

.bottom_header .navbar-nav {
  margin-left: 30px;
}

/* @media only screen and (min-width:801px) {
  .icons span {
    margin-right: -1px !important;
  }
} */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .cart-div {
    display: inline-block;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 1200px) {
    .cart-div {
        padding-left: 20% !important;
    margin-top: 10px !important; 
    }
} */

@media only screen and (max-width: 993px) {
  /* .cart-div {
    padding: 0px;
  } */
  /* .icons span {
        margin-right: -2px !important;
    } */
  .icons {
    padding: 20px !important;
  }
}

.icons {
  color: #fff;
}

.icons i {
  padding-right: 5px;
  font-size: 17px;
}

.icons span {
  font-size: 17px;
  font-family: var(--h-family);
  /* margin-right: 8px; */
  font-weight: 500;
}

@media only screen and (max-width: 991px) {
  .icons {
    text-align: center;
  }
  .nav-item select {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 800px) {
  /* .search_field {
        margin-bottom: -40px;
    } */
  .payment_type {
    padding-right: 2px !important;
  }
}

.mid_header .row {
  margin-left: 0 !important;
}

.navbar {
  text-align: center;
}

.navbar-toggler-icon {
  color: var(--green-color);
  font-size: 32px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.span-sign {
  /* padding: 6px; */
  border-radius: 0.25rem;
  /* border: 1px solid #fff; */
  margin-right: 10px;
}

.nav-item .icons .nav-link {
  border: none;
}

.nav-item .icons:hover .nav-link {
  border: none;
}

.nav-item .icons .nav-link .linked:hover {
  color: var(--p-color);
  transition: 0.5s;
}

.nav-item .icons .nav-link .linked:hover i {
  color: var(--p-color);
  transition: 0.5s;
}

/* .nav-item .icons .nav-link .linked:hover {
    color: var(--p-color);
    transition: 0.5s;
    text-decoration: underline;
} */

.nav-link i {
  color: var(--green-color);
}

.navbar-nav1 {
  display: none;
}

@media only screen and (max-width: 993px) {
  .navbar-nav1 {
    display: inline;
    margin: auto;
  }
}

@media only screen and (max-width: 993px) {
  .navbar-nav .icons {
    display: none;
  }
}

.span-sign:hover {
  /* background-color: var(--g-color);
    color: black;
    box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 20%);
    cursor: pointer;
    border-radius: 0.25em; */
  /* color: #fff;
    border-radius: 0.25rem; */
  /* color: var(--p-color); */
}

.search_field .form-control {
  height: 40px;
}

.search_field i {
  font-size: 20px;
}

.nav-item {
  font-size: 15px;
  /* margin-left: 12px; */
  /* margin-right: 16px; */
}

.right-nav-items .nav-item {
  margin-left: 10px;
}

/* .nav-link {
    border: 1px solid rgb(24 140 62);
} */

.nav-link:hover {
  /* border: 1px solid #fff; */
  /* border-radius: 0.25rem; */
  color: var(--p-color);
}

.nav-link:focus {
  /* color: #deba62; */
  color: var(--p-color);
}

/* .nav-link:active {
    color: yellow;
} */

.link-cart {
  color: var(--white-color);
  border: 1px solid var(--p-color);
  /* margin-right: 10px; */
  padding: 6px;
  border-radius: 0.25rem;
}

.link-cart:hover {
  color: white;
  border: 1px solid #fff;
  border-radius: 0.25rem;
}

.select {
  margin-top: 8px;
  padding: 4px 20px;
  font-family: var(--h-family);
  border: none;
  color: var(--green-color);
  font-size: 17px;
}

.nav-item a {
  color: var(--green-color);
}

/* ..........................login-page...................... */

.login {
  margin: 60px 0;
}

.login-image {
  height: 300px;
  /* width: 200px; */
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .login-image {
    display: none;
  }
  .register-image {
    display: none;
  }
  .payment_type img {
    height: 22px !important;
  }
}

.login-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.login-title {
  font-family: var(--h-family);
}

.login-title h6 {
  font-size: 30px;
  color: var(--a-color);
  text-transform: capitalize;
  font-weight: 600;
}

.login-title p {
  font-size: 13px;
  margin-top: 10px;
}

.input-group {
  border-radius: 0.25rem;
}

.login-form .input-group {
  width: 100%;
}

.login-form .input-group input {
  border-right: none;
  border-radius: 0.25rem !important;
}

.login-form .form-check-label {
  font-size: 15px;
  text-transform: capitalize;
}

.login-form label {
  width: 100%;
  font-family: var(--h-family);
  font-weight: bold;
  opacity: 80%;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  margin-bottom: 0.125rem;
  padding-left: -1px;
}

.login-form .input-group-text i {
  color: #ebebeb;
}

.cart-sup {
  background-color: var(--white-color);
  color: var(--h-color);
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 14px;
}

.underline-click {
  color: var(--a-color);
  text-decoration: underline;
}

.main-topic .btn {
  display: inline;
  padding: 6px !important;
}

.main-topic span {
  color: var(--green-color);
}

.button .btn {
  color: var(--white-color);
  text-transform: capitalize !important;
  font-size: 15px;
  width: 100%;
  background-color: var(--green-color);
  margin-top: 20px;
  padding: 6px 0;
  border-radius: 5px;
  border: none;
  text-align: center;
}

.button .btn:hover {
  color: var(--white-color);
  /* background-color: #87101d; */
  background-color: rgb(21, 99, 47);
  border: none;
}

.back-heading {
  margin-bottom: 10px;
  font-family: var(--p-family);
}

.back-heading p {
  font-size: 12px;
}

.login .back-heading {
  text-align: center;
  margin-left: 140px;
}

/* ..................reset-password.............. */

.go-to {
  color: var(--black-color);
  opacity: 60%;
}

.register-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  padding: 0 64px 64px 64px;
}

.register-image img {
  height: 275px;
  width: 100%;
  overflow: hidden;
}

.register-main {
  margin: 32px 0;
}

.reset-verification-modal {
  text-align: center;
  padding: 36px 0;
}

.tick p {
  background-color: var(--h-color);
  display: inline-block;
  color: var(--white-color);
  border-radius: 50%;
  padding: 6px 12px;
}

.reset-topic {
  margin: 16px 0;
}

.reset-topic p {
  margin: 16px 0;
}

.btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* ................footer.................... */

@media only screen and (max-width: 768px) {
  .footer {
    text-align: center !important;
  }
}

.footer {
  background-color: var(--p-color);
  color: var(--white-color);
}

.first_section {
  padding-top: 20px;
}

.first_section ul {
  margin-top: 10px;
  padding: 0 !important;
}

.first_section li {
  list-style: none;
  font-size: 16px;
  text-transform: capitalize;
  font-family: var(--p-family);
  padding-top: 5px;
}

.first_section .li-footer {
  margin-right: 20px;
}

@media only screen and (max-width: 800px) {
  .first_section .li-footer {
    margin-right: 0 !important;
  }
}

.first_section li > input {
  border: none;
  padding: 4px 4px;
  width: 100%;
  border-radius: 0.25rem;
}

.first_section > li > input:focus {
  border: #fff !important;
}

.first_section .first_section_terms {
  border: 1px solid var(--p-color);
  padding: 3px;
}

.first_section .first_section_terms :hover {
  border: 1px solid #fff;
  padding: 3px;
  color: #fff;
  border-radius: 0.25rem;
}

.footer-submit {
  border: none;
  background-color: var(--g-color);
  font-size: 12px;
  margin-top: 15px;
  padding: 4px 16px;
  text-transform: uppercase;
  border-radius: 0.25rem;
}

.footer-submit:hover {
  background-color: var(--black-color);
  color: var(--g-color);
}

.social_icon {
  margin-top: 20px;
}

.social_icon i {
  padding-right: 20px;
  font-size: 20px;
  color: white;
}

.social_icon i:hover {
  color: #cfcfcf;
  transition: 0.5s;
}

.first_section input {
  margin-top: 10px;
}

.first_section .btn {
  border: 1px solid var(--white-color);
  text-align: center;
  margin-top: 20px;
  border-radius: 0 !important;
}

.map {
  margin-top: 30px;
  height: 200px;
  width: 100%;
  text-align: center;
}

.map .mapimg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 820px) {
  .mapimg {
    height: 160px !important;
    width: 160px !important;
  }
  .map {
    padding-left: 0 !important;
  }
}

.copyright {
  margin: 20px 0;
}

.copyright_text {
  text-align: center;
  font-family: var(--p-family);
}

.copyright_text h6 {
  font-size: 18px;
}

.payment {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.payment_type {
  padding-right: 20px;
  margin-bottom: 30px;
  object-fit: cover;
}

.payment_type img {
  height: 42px;
}

/*------- main-banner -------*/

/* .main-banner {
    margin: 36px 0; 
} */

.main-banner-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.first_section img {
  height: 50px;
  width: 80px;
  object-fit: cover;
}
.category-img {
  /* padding: 16px 0; */
  border-radius: 50%;
  height: 200px;
  /* width: 200px; */
}

.category-img img {
  display: inline-block !important;
  border-radius: 50%;
  /* object-fit: cover; */
  height: 100%;
  width: 200px;
  margin-top: 10px;
  transform: scale(1.01);
}

.category-img:hover img {
  /* box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 20%); */
  transform: scale(1);
  /* margin: 0px 22px; */
  transition: 0.5s;
}

/* .category-img:hover {
    color: var(--green-color);
} */

.category-img a:hover {
  color: var(--green-color);
  transition: 0.5s;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: var(--p-color);
  height: 26px;
  width: 26px;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 360px) {
  .main-banner-img img {
    height: 400% !important;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 16px !important;
    width: 16px !important;
  }
}

.main-banner-img img {
  height: 450px;
  width: 100%;
  overflow: hidden;
}

.slider-main {
  text-align: center !important;
}

.slider-main a {
  text-decoration: none;
  color: var(--black-color);
}

.slider-main img {
  /* height: 4rem; */
  /* height: 100px;
    width: 100px; */
  /* border-radius: 50%; */
}

.category-main {
  margin: 36px;
  text-align: center;
}

.category-main p {
  font-size: 12px;
  font-weight: bold;
  padding-top: 8px;
}

/* feature-products */

.main-topic {
  text-align: center;
  color: var(--p-color);
  font-size: 26px;
  font-weight: bold;
}

.feature-products {
  margin: 30px 0;
}

.cart-main {
  padding: 31px;
  border-right: 1px solid #ebebeb;
  transition: 0.5s;
}

/* .cart_icons .row {
    padding-left: 0 !important;
    padding-right: 0 !important;
} */

.cart_icons .col-md-6 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (max-width: 700px) {
  .feature-img,
  .feature-data {
    padding: 0 1rem !important;
  }
  .mobilever_cart {
    padding: 0 1rem !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 900px) {
  .feature-img p {
    left: 77% !important;
  }
}

.feature-data {
  text-align: center;
  margin-top: 15px;
}

.mobilever_cart {
  display: flex !important;
  margin: 10px 0;
}

.mobilever_cart p {
  padding: 4px 10px;
  background-color: var(--h-color);
  color: #fff;
  margin-right: 10px;
  border-radius: 0.25rem;
  display: none !important;
  text-transform: uppercase;
  font-size: 13px;
}

.mobilever_cart p i {
  padding: 0 3px;
}

/* @media (max-width: 600px) {
    .mobilever_cart p {
        display: block !important;
    }
    .cart_icons {
        display: none !important;
    }
} */

@media only screen and (min-width: 280px) {
  .feature_img p {
    left: 71% !important;
  }
}

.feature-img {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.feature-img img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}
.footer {
  text-align: center;
}
.cart_icons {
  /* display: flex; */
  /* justify-content: center; */
  margin-top: 12px;
}

.mid_header .row {
  margin-right: 0 !important;
}

/* .wish-hand {
    margin-left: 12px;
} */

.cart-hand {
  border-radius: 0.25rem;
  background-color: var(--green-color);
  transition: 0.5s;
}

.cart-hand:hover {
  background-color: rgb(21, 99, 47);
}

.cart-hand .btn {
  color: #fff;
}

.feature-img p {
  /* position: absolute; */
  cursor: pointer;
  /* top: 1%;
    left: 80%; */
  font-size: 12px;
  color: white;
  background-color: var(--p-color);
  padding: 4px 6px;
  /* border-radius: 50%; */
}

.cart-main:hover .feature-data a .cart-content-name {
  color: var(--p-color);
  transform: scaleX(1.1);
  transition: 0.6s;
}

.cart-main {
  /* border: 1px solid #fff; */
}

.cart-main:hover {
  /* border: 1px solid #ebebeb; */
  box-shadow: 0 0 5px #d9d2d2 !important;
  cursor: pointer;
}

.cart-content-price {
  font-size: 17px;
  font-weight: 800;
  color: var(--p-color);
}

.cart-content-icon {
  color: gold;
  font-size: 12px;
}

.feature-data a {
  text-decoration: none;
}

.feature-data a .cart-content-name {
  font-size: 19px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--black-color);
}

/*    second bannner    */

.sec-banner {
  margin: 60px 0;
}

.second-banner-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.second-banner-img img {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

/* Why choose us */

.choose-main {
  margin: 30px 0;
}

.choose-content {
  text-align: center;
  height: 100%;
  object-fit: cover;
  margin: 26px 0;
}

.choose-content:hover {
  rotate: 360%;
}

.choose-content img {
  height: 160px;
  overflow: hidden;
}

.choose-content p {
  font-weight: bold;
}

.main-secondfeatured {
  margin: 64px 0 36px;
}

/* learnabout css */

.learnabout-content {
  margin: 16px 0 64px;
  text-align: justify;
}

.learnabout-content p {
  font-size: 16px;
}

/*  detail css  */

.detail-main {
  margin: 86px 0;
}

.detail-img img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  padding: 30px;
  border: 2px solid var(--g-color);
}

.detail-img {
  height: 100% !important;
  width: 100%;
  /* overflow: hidden !important; */
}

.detail-content {
  margin: 20px 0 56px 36px;
  padding: 40px;
  /* border: 2px solid var(--g-color); */
  /* box-shadow: 0 0 5px #666 !important; */
}

.detail-content-head h3 {
  color: var(--green-color);
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: 1px solid;
  display: inline-block;
}

.detail-content-head h4 {
  font-size: 25px;
  margin: 16px 0;
  font-weight: 600;
  color: var(--a-color);
}

.detail-content-head h4 > del {
  opacity: 50%;
  margin-left: 16px;
}

.detail-detail {
  margin: 20px 0;
}

.detail-detail-content p {
  margin-bottom: 14px;
  font-size: 15px;
  font-weight: 550;
}

@media only screen and (max-width: 360px) {
  .detail-detail-content p {
    height: 44px !important;
  }
  .detail-count {
    text-align: center !important;
    margin-left: 43px !important;
  }
  .subtotal-mob {
    font-weight: bold;
  }
  .cart-btn {
    margin-left: 43px !important;
  }
}

.units {
  color: var(--a-color);
}

.detail-count {
  background-color: var(--t-color);
  margin-left: 55px;
  /* width: 30%; */
  padding: 4px 8px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.detail-count button {
  border: none;
  background-color: var(--t-color);
  color: var(--s-color);
  font-size: 22px;
  font-weight: bold;
  padding: 0 20px;
}

.detail-count input {
  background-color: var(--t-color);
  border: none;
  width: 25%;
  text-align: center;
  /* height: 35px;
    border-right: 3px solid #ebebeb;
    border-left: 3px solid #ebebeb; */
}

.cart-btn:hover {
  cursor: pointer;
}

.cart-btn p {
  color: var(--h-color);
  display: inline-block;
  padding: 6px 13px;
  background-color: var(--g-color);
}

.cart-btn p > span {
  padding: 0 6px;
}

.cart-btn .wishlist:hover {
  /* background-color: var(--s-color); */
  /* color: #000; */
  background-color: rgb(21, 99, 47);
}

.cart-btn .addcart:hover {
  background-color: #f9b7be;
}

.detail-text {
  text-align: justify;
  margin-top: 30px;
}

.detail-text h4 {
  color: var(--s-color);
  font-weight: 600;
}

.detail-text p {
  margin: 12px 0;
}

.other-main h3 {
  color: var(--s-color);
  font-weight: bold;
  margin: 50px 0 16px;
}

/*     ADD TO CART CSS     */

@media only screen and (max-width: 800px) {
  .cart-main {
    padding: 0 !important;
  }
  .detail-count {
    margin-left: 0 !important;
    width: 110 !important;
  }
  tr {
    font-size: 12px;
  }
  .detail-count button {
    padding: 0 5px !important;
  }
  .detail-count input {
    width: 56% !important;
  }
}

.cart-main {
  margin: 25px 0 25px;
}

.cart-table {
  text-align: center;
}

.cart-form {
  margin: 16px 0;
}

.btn-shopping .btn {
  width: fit-content;
  padding: 6px 10px;
}

@media only screen and (max-width: 360px) {
  .remove {
    width: 0.25rem !important;
  }
  .remove i {
    font-size: 12px;
    padding: 3px 2px !important;
    border-radius: 50% !important;
  }
  .product-img {
    width: 0.25rem !important;
    font-size: 12px;
  }
  .product-img img {
    height: 35px !important;
  }
  .product-name {
    font-size: 12px;
  }
  .price {
    font-size: 12px;
  }
  .quantity {
    font-size: 12px;
    text-align: center !important;
  }
  .unitprice {
    font-size: 12px;
  }
}

.remove {
  width: 5rem;
}

.remove i {
  background-color: var(--p-color);
  color: var(--g-color);
  border-radius: 50%;
  padding: 3px 4px;
  opacity: 60%;
}

.product-img {
  width: 13rem;
}

.product-img img {
  height: 80px;
  object-fit: contain;
  overflow: hidden;
}

.product-name {
  width: 23rem;
}

.price {
  width: 11rem;
}

.quantity {
  width: 17rem;
}

.subtotal {
  font-family: var(--p-family);
}

.total-cart {
  font-size: 20px;
  font-weight: bold;
}

.checkout button {
  border: none;
  width: 100%;
  margin: 18px 0;
  color: var(--t-color);
  border-radius: 10px;
  background-color: var(--s-color);
  padding: 8px;
}

.checkout button:hover {
  background-color: var(--black-color);
}

/*    terms and conditions    */

.terms-main {
  margin: 64px 0;
  text-align: justify;
}

.terms-body h3 {
  margin-bottom: 30px;
}

.terms-body p {
  margin-bottom: 20px;
}

/*   categories content   */

.categories-content-main {
  margin: 64px 0;
}

.categories-content-bar {
  background-color: var(--t-color);
  padding: 12px 0;
  font-weight: 500;
  width: 100%;
  box-shadow: 3px 2px 12px 3px rgb(0 0 0 / 10%);
}

.left-bar {
  padding: 10px 0px 10px 10px;
}

.no-items {
  text-align: center;
  margin-top: 40px;
}

.pagination-main {
  justify-content: center !important;
  display: flex;
}

@media only screen and (max-width: 700px) {
  .profile-changes-btn button {
    font-size: 10px;
    width: 80% !important;
  }
  .edit-profile-main {
    text-align: center !important;
  }
  .edit-profile-main h3 {
    color: var(--black-color) !important;
  }
  .link-profile p {
    color: var(--g-color) !important;
  }
  .link-profile p:hover {
    background-color: var(--g-color) !important;
    border-radius: 10px;
    padding: 4px 5px;
    color: var(--h-color) !important;
  }
  .left-bar ul > li {
    font-size: 10px !important;
  }
  .right-bar {
    font-size: 10px !important;
  }
  .right-bar form {
    width: 100px !important;
  }
  .categories-content-bar {
    padding: 6px 0 !important;
  }
}

.left-bar ul > li {
  display: inline-block;
  list-style-type: none;
  margin-left: 12px;
  font-size: 12px;
}

.right-bar {
  font-size: 12px;
  float: right;
}

.right-bar form {
  width: 181px;
}

.categories-content-option {
  padding: 12px;
}

.categories-content-select {
  border: none;
  padding: 6px 6px;
  margin-left: 6px;
  width: 60%;
}

.form-control-price {
  margin-left: 6px;
  width: 32%;
}

@media only screen and (max-width: 990px) {
  .form-control-price {
    margin-bottom: 20px !important;
  }
}

.form-control-price :focus {
  outline-style: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

@media only screen and (max-width: 700px) {
  .manage-profile-main h4 {
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0px;
    word-spacing: 0.1px !important;
  }
  .manage-profile-main ul {
    padding-left: 15px !important;
    line-height: 8px;
  }
  .manage-profile-main ul > li {
    font-size: 10px;
  }
  .manage-profile-main ul > li > p {
    font-size: 8px !important;
  }
  .main-left-bar {
    background-color: var(--h-color);
    color: var(--g-color);
    padding: 8px 16px;
    border-radius: 10px;
  }
}

.categories-content-body {
  margin-top: 30px;
}

.code-verification-main {
  margin: 64px 0;
  text-align: center;
}

.code-verify {
  margin: 36px 0;
}

.code-input {
  margin: 26px 0;
  padding: 26px 0;
  background-color: var(--a-color);
  border-radius: 50%;
}

@media only screen and (max-width: 360px) {
  .code-input {
    margin: 5px 0 !important;
    padding: 9px 6px !important;
  }
  .code-input input {
    width: 20px !important;
  }
}

.code-input input {
  width: 40%;
  border: none;
  padding: 6px;
}

/*     Profile Css     */

.profile-main {
  margin: 64px 0;
  font-family: var(--h-family);
}

.manage-profile-main h4 {
  font-family: var(--h-family);
  font-size: 22px;
  font-weight: bold;
}

.manage-profile-main ul > li {
  list-style-type: none;
  margin: 8px 0;
}

.manage-profile-main ul > li:hover p {
  background-color: var(--a-color);
  color: var(--white-color) !important;
  display: inline-block;
  border-radius: 0.25rem;
}

.manage-profile-main ul > li > p {
  padding: 4px 0;
}

.edit-profile-main h4 {
  margin-bottom: 16px;
}

.edit-profile-content {
  background-color: var(--p-color);
  color: var(--g-color);
  padding: 64px;
}

.change-btn {
  color: #fff;
  text-decoration: none;
}

.change-btn:hover {
  background-color: var(--g-color);
  color: black;
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 20%);
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .cartbutton .btn {
    font-size: 12px !important;
    line-height: 1 !important;
  }
  .categories-content-select {
    width: auto !important;
  }
}

@media only screen and (max-width: 700px) {
  .edit-profile-wishlist {
    padding: 26px 0;
  }
  .edit-profile-content {
    padding: 36px !important;
  }
}

.cart-btn .wishlist {
  background-color: var(--green-color);
  color: #fff;
}

.cart-btn .wishlist i {
  margin-right: 3px;
}

.edit-profile-wishlist {
  background-color: var(--p-color);
  color: var(--g-color);
}

.name-field {
  margin-bottom: 26px;
}

.name-field input {
  border: none;
  padding: 4px 8px;
  width: 100%;
}

.name-field select {
  border: none;
  padding: 4px 8px;
  width: 100%;
}

.profile-changes-btn {
  text-align: center;
  margin: 26px 0;
}

.profile-changes-btn button {
  border: none;
  width: 50%;
  padding: 4px 0;
  border-radius: 0.25rem;
}

.profile-changes-btn button:hover {
  background-color: var(--black-color);
  color: var(--g-color);
}

.link-profile {
  text-decoration: none;
  color: var(--black-color);
}

.link-profile:hover {
  color: var(--a-color) !important;
}

.edit-profile-main h3 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 12px;
  color: var(--h-color);
}

.modal-title {
  color: var(--black-color);
  font-size: 20px;
  font-weight: 600;
}

.modal-body label {
  color: var(--black-color) !important;
  padding: 10px 0 5px 0;
  font-size: 15px;
  font-family: var(--h-family);
  font-weight: 600;
}

.modal-body input {
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #ebebeb;
}

/* contact page */

.contact_socials {
  margin: 35px 0 25px;
}

.contact_heading {
  margin: 25px 0;
  text-align: center;
  font-family: var(--h-family);
}

.contact_heading h5 span {
  color: var(--green-color);
}

.contact_heading h5 {
  font-size: 28px;
  letter-spacing: 1px;
  color: var(--p-color);
  font-weight: 600;
}

.contact_map {
  height: 400px;
  width: 100%;
  overflow: hidden;
}

.contact_map .whole_map {
  height: 100%;
  width: 100%;
}

.contact_info {
  margin-top: 30px;
  margin-bottom: 30px;
}

.basic_info {
  margin-top: 25px;
  font-family: var(--h-family);
  box-shadow: 0 0 5px #d9d2d2 !important;
}

.basic_info .info_heading {
  padding: 30px;
  color: var(--p-color);
}

.basic_info .info_heading h5 {
  font-size: 25px;
}

.basic_info form {
  padding: 30px;
}

.contact_info .form-label {
  font-weight: 600;
}

.info {
  display: flex;
  align-items: center;
  margin: 30px 0;
  justify-content: center;
}

.nums:hover {
  color: var(--p-color);
  cursor: pointer;
}

.nums h5 {
  font-size: 18px;
  padding-top: 20px;
}

.info i {
  padding-right: 30px;
  font-size: 20px;
  padding-top: 20px;
}

.social_icon_contact {
  text-align: center;
  padding: 20px;
}

.social_icon_contact i {
  padding: 5px 25px;
  font-size: 25px;
  transition: 0.5s;
}

.social_icon_contact i:hover {
  color: var(--p-color);
}

.social_icon_contact a {
  text-decoration: none;
  color: #000;
}

.basic_info .btn {
  background-color: var(--green-color);
  color: var(--white-color);
  width: auto;
  padding: 5px 15px;
}

.basic_info .btn-submit {
  text-align: center;
}

li {
  list-style: none;
}

.info_heading {
  text-align: center;
}

.info_heading h5 span {
  color: var(--green-color);
}

.info_heading h5 {
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--p-color);
}

/* about-page */

.about_image {
  height: 400px;
  width: 100%;
}

.about_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about_desc {
  margin: 20px 0;
  font-family: var(--p-family);
}

.about_desc p {
  font-size: 16px;
}

/* -- Payment -- */

.payment-method {
  padding: 20px 0px 10px 0px;
  font-size: 22px;
  font-weight: 400;
  color: var(--white-color);
}

.payment-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  margin-bottom: 4px;
  color: #404040;
}

.payment-sub-title {
  font-size: 12px;
  line-height: 14px;
  color: #757575;
}

/* .esewa {
  padding-bottom: 10px;
  height: 82px;
  width: 62px;
}

.esewa img {
  height: 100%;
  width: 100%;
  object-fit: cover;
} */

.payment-section {
  background-color: aliceblue;
  padding: 10px 10px;
  margin-top: 20px;
}

.payment-section :hover {
  cursor: pointer;
}

.payment-active {
  border: 5px solid #2856c8;
}

.payment-section img {
  width: 100%;
  height: 70px;
  margin-bottom: 15px;
}

.input-field {
  width: 100%;
  height: 40px;
  /* border-radius: 6px; */
  padding: 10px;
  border: none;
}

.input-field:focus {
  outline: none;
}

.right {
  float: right !important;
}

.left {
  float: left;
}

.order-summary {
  margin: 14px 35px;
  padding: 20px;
  background-color: var(--p-color);
  color: white;
}

.form-control-second {
  margin: 0;
}

.total-table {
  width: 100%;
}

.summary-btn {
  margin-top: 10px !important;
}

/* @media screen and (max-width: 800px) {
    .form-control {
         margin-top: 18px; 
    }
} */

.pop-up-buttom {
  text-align: center;
  margin: 0px 36px;
}

.pop-up-buttom button {
  width: 100%;
}

/* checkout section */

.checkout-header {
  padding-top: 30px;
}

.checkout-section {
  background-color: var(--p-color);
  padding: 25px;
  margin: 30px 0px;
}

.checkout-submit {
  margin-top: 25px;
  padding: 5px 10ppx;
  font-size: 13px;
  border-radius: 0;
}

.checkout-section label {
  color: white;
}

.modal-footer {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-footer .btn {
  width: fit-content;
  padding: 5px 10px;
}

.error-message {
  color: red !important;
  font-size: 10px;
  font-weight: 600;
}

.filter-btn {
  text-align: center;
  margin-top: 26px;
}

.filter-btn button:hover {
  color: var(--g-color);
  background-color: var(--s-color) !important;
}

.filter-btn button {
  background-color: var(--p-color) !important;
  color: var(--t-color);
  padding: 4px 24px;
  border: none;
}

.order-table {
  overflow-x: hidden;
  /* display: none; */
}

.order-list-table td {
  text-align: right;
}

/* @media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .search-name {
    width: 15rem;
  }
} */
